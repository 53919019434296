<template>
  <div id="app">
    <Offline class="z-index" @detected-condition="handleConnectivityChange"/>
    <router-view/>
  </div>
</template>

<script>
import Vue from 'vue'
import Offline from 'v-offline'
import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue)

export default {
  components: { Offline },
  created () {
    /* if (window.screen.lockOrientation('landscape')) {
      alert('Vira a tela do seu smartphone para vertical')
    }
    */
  },
  methods: {
    handleConnectivityChange (status) {
      if (!status) { this.toast('b-toaster-top-full') }
    },
    toast (toaster, append = false) {
      this.$bvToast.toast(`Você está offline.`, {
        title: `Conexão a internet perdida!`,
        toaster: toaster,
        solid: true,
        appendToast: append
      })
    }
  }
}

</script>

<style lang="scss">
@import url("assets/fonts/font-config.css");
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@100;300&display=swap');
  .rise-top-card{
    position: absolute;
    width: 50px;
    @media(max-width:768px){
      width: 30px;
    }
  }
*{
  margin: 0;
  box-sizing: border-box;
  font-family: "RubikRegular",sans-serif;
  outline: none;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #4b335ad8;
    background-color: #fff
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #4b335a;
    background-color: rgb(192, 183, 183);
  }
  &::-webkit-scrollbar-button:vertical:increment {
    background-position: -64px -16px;
  }
  &::-webkit-scrollbar-button:vertical:decrement {
    background-position: 0 -16px;
  }
  &::-webkit-scrollbar-button:horizontal:increment {
    background-position: -32px -16px;
  }
  &::-webkit-scrollbar-button:horizontal:decrement {
    background-position: -96px -16px;
  }
  &::-webkit-scrollbar-corner {
    background-color: #57346d;
  }
}

.z-index{
  z-index: 999999!important;
  position: absolute;
}
.opacity-small{
  opacity: 0.35!important;
}
.Contratado{
  background: red;
}
.Disponível{
  background: #01D059;
}
.Novo{
  background: #009DFF;
}
.Em-Selecção{
  background: orange;
}

.star-rating {
  direction: rtl;
  display: inline-block;
}
.star-rating input[type=checkbox] {
  display: none;
}
.star-rating label {
  color: #ccc;
  font-size: 20px;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  @media(max-width:768px){
    font-size: 18px!important;
  }
}
.star-rating label:hover,
.star-rating label:hover ~ label,
.star-rating input[type=checkbox]:checked ~ label {
  color: red;
}
.star-checked{
  color: red;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  overflow: hidden;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
