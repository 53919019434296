<template>
  <div class="default-layout">
      <app-menu :theme="theme" />
      <router-view />
  </div>
</template>

<script>
import MenuComponent from '@/components shared/MenuComponent'
export default {
  name: 'DefaultLayout',
  components: {
    'app-menu': MenuComponent
  },
  data () {
    return {
      theme: {
        colorIcon: 'primary'
      }
    }
  },
  mounted () {
    this.theme = { ...this.theme, ...this.$route.meta?.theme }
  },
  watch: {
    $route: function () {
      if (this.$route.meta.theme) {
        const theme = this.$route.meta.theme
        if (theme.colorIcon) {
          this.theme = { ...this.theme, ...this.$route.meta?.theme }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.router-link-active {
  z-index: 9;
  position: fixed
}
img.logo {
  margin: 0;
  display: none;
  transition: all .26s linear;
}

@media (min-width: 960px) {
  img.logo {
    display: block !important;
    height: auto;
    width: 200px;
    margin-top: 50px;
    position: fixed;
    left: 50px;
  }
}
</style>
