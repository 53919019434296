import Vue from 'vue'
import Vuex from 'vuex'
import Login from './modules/auth/login'
import Register from './modules/auth/register'
import Profissional from './modules/app/profissional'
import { MinhaArea } from './modules/app/minha_area'
import { Active } from './modules/auth/active'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    access_token: window.sessionStorage.getItem('@rise_token_parceiro'),
    info: {},
    functions: {}
  },
  mutations: {
    setFunctions (payload) {
    }
  },
  actions: {
  },
  modules: {
    login: Login,
    Profissional: Profissional,
    register: Register,
    minha_area: MinhaArea,
    active_account: Active
  }
})
