import { url } from '@/api/base-url'

const dictonary = {
  object: 'object',
  success: 'success'
}

const MinhaArea = {
  namespaced: true,
  state: {
    profile: {},
    products: {}
  },
  mutations: {
    SET_MINHA_AREA_INFO (state, profile) {
      state.profile = profile
    },
    SET_PRODUCTS (state, payload) {
      state.products = payload
    }
  },
  getters: {
    MinhaAreaInfo (state) {
      return state.profile
    }
  },
  actions: {
    getMinhaAreaInfo ({ commit }, endepoint) {
      return url.get(`${endepoint}`).then(({ data }) => {
        if (data[dictonary.success]) {
          commit('SET_MINHA_AREA_INFO', data[dictonary.object])
        }
        return data
      })
    },
    getProducts ({ commit }) {
      return url.get('produtos').then(res => res.data.object).then(data => {
        commit('SET_PRODUCTS', data)
        return data
      })
    },
    purchaseProduct (context, id) {
      return url.post('compras', { idProduto: id }).then(res => res.data.object)
    },
    getReferencePayment () {
      return url.get('compras').then(res => res.data.object)
    }
  }
}

export { MinhaArea }
