export const isAuthenticated = () => {
  const accessToken = sessionStorage.getItem('@rise_token_profissional')
  const tokenExp = sessionStorage.getItem('@rise_token_profissional_expiration')
  const todayTimeStamp = Math.floor(Date.now() / 1000)

  return accessToken && tokenExp && (tokenExp > todayTimeStamp)
}

export const isAuthenticatedParceiro = () => {
  const accessToken = sessionStorage.getItem('@rise_token_parceiro')
  const tokenExp = sessionStorage.getItem('@rise_token_parceiro_expiration')
  const todayTimeStamp = Math.floor(Date.now() / 1000)

  return accessToken && tokenExp && (tokenExp > todayTimeStamp)
}
