import { url } from '@/api/base-url'

export function getRegisterList () {
  return url.get('Profissionais/ListasFormulario')
}

export function insertEducationProfissional (data) {
  return url.post('profissionais/addEducacao', data)
}
export function deleteEducationProfissional (id) {
  return url.post('profissionais/deleteEducacao?id=' + id)
}
export function insertFormacaoProfissional (data) {
  return url.post('profissionais/addFormacao', data)
}
export function deleteFormacaoProfissional (id) {
  return url.post('profissionais/deleteFormacao?id=' + id)
}

export function insertExperienciaProfissional (data) {
  return url.post('profissionais/addExperienciaProfissional', data)
}
export function deleteExperienciaProfissional (id) {
  return url.post('profissionais/deleteExperienciaProfissional?id=' + id)
}
export function insertVideoCVProfissional (file) {
  const formData = new FormData()
  formData.append('file', file)
  return url.post('profissionais/UpdateVideoCV', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export function createCandidate (data) {
  return url.post('Profissionais', data)
}

export function createFile (file) {
  const formData = new FormData()
  formData.append('file', file)
  return url.post('profissionais/UploadVideoCV', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
