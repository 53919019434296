import store from '@/store'
import { isAuthenticated, isAuthenticatedParceiro } from '@/utils/auth'

const protectedRoutes = [
  {
    beforeEnter: (to, from, next) => {
      if (isAuthenticatedParceiro()) {
        store.dispatch('parceiros/minhaarea')
        next()
      } else {
        window.sessionStorage.removeItem('@rise_token_parceiro')
        next({ name: 'Home' })
      }
    },
    path: '/parceiro',
    name: 'MyAreaParceiro',
    component: () => import('@/views/MyAreaParceiro.vue')
  },
  {
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) {
        store.dispatch('profissionais/minhaArea')
        next()
      } else {
        window.sessionStorage.removeItem('@rise_token_profissional')
        next({ name: 'Home' })
      }
    },
    path: '/profissional',
    name: 'MyAreaProfissional',
    component: () => import('@/views/MyAreaProfissional.vue')
  }
]

export { protectedRoutes }
