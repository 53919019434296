import Vue from 'vue'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './boot/vue-swiper'
import './boot/bootstrap'
import './helpers/components.global.helper'
Vue.config.productionTip = false

Vue.use(Vuelidate)
axios.defaults.baseURL = 'http://riseapi-dev.digitalfactory.co.ao'
Vue.filter('dateString', function (date) {
  return date ? date.toLocaleDateString() : ''
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
