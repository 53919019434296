import * as RegisterService from '@/api/services/register'
const initialState = {
  'nome': '',
  'apelido': '',
  'videoCVUrlTmp': '',
  'dataNascimento': '',
  'descricao': '',
  'numDoc': '',
  'curso': '',
  'tipoDoc': 0,
  'sexo': -1,
  'idPais': 0,
  'idCidade': 0,
  'idNacionalidade': 0,
  'idLinguaMaterna': 0,
  'idHabilitacao': 0,
  'idAvatar': 0,
  'userName': '',
  'password': '',
  'idIndicativo': 0,
  'phoneNumber': '',
  'email': '',
  'formacoes': [],
  'funcoes': [],
  'pontosMelhorias': [],
  'pontosFortes': [],
  'idiomas': [],
  'experienciasProfissional': [],
  'educacoes': [],
  'respostas': [],
  'disponibilidades': []
}
export default {
  namespaced: true,
  state: {
    register: Object.assign(initialState),
    picklists: {
      nacionalidades: [],
      idiomas: [],
      areasEstudo: [],
      areasFormacao: [],
      habilitacoes: [],
      paises: [],
      duracoes: [],
      experiencias: [],
      grausAcademicos: [],
      avatares: [],
      perguntas: [],
      valencias: [],
      funcoes: [],
      sectores: [],
      nivelIdiomas: [],
      disponibilidades: [],
      indicativos: [
        { id: 244, nome: '244 (AO)' },
        { id: 351, nome: '351 (PT)' },
        { id: 93, nome: '93 (AF)' },
        { id: 355, nome: '355 (AL)' },
        { id: 49, nome: '49 (DE)' },
        { id: 376, nome: '376 (AD)' },
        { id: 1264, nome: '1264 (AI)' },
        { id: 672, nome: '672 (AQ)' },
        { id: 1268, nome: '1268 (AG)' },
        { id: 966, nome: '966 (SA)' },
        { id: 213, nome: '213 (DZ)' },
        { id: 54, nome: '54 (AR)' },
        { id: 374, nome: '374 (AM)' },
        { id: 297, nome: '297 (AW)' },
        { id: 61, nome: '61 (AU)' },
        { id: 43, nome: '43 (AT)' },
        { id: 994, nome: '994 (AZ)' },
        { id: 32, nome: '32 (BE)' },
        { id: 1242, nome: '1242 (BS)' },
        { id: 973, nome: '973 (BH)' },
        { id: 880, nome: '880 (BD)' },
        { id: 1246, nome: '1246 (BB)' },
        { id: 501, nome: '501 (BZ)' },
        { id: 229, nome: '229 (BJ)' },
        { id: 975, nome: '975 (BT)' },
        { id: 375, nome: '375 (BY)' },
        { id: 95, nome: '95 (MM)' },
        { id: 591, nome: '591 (BO)' },
        { id: 387, nome: '387 (BA)' },
        { id: 267, nome: '267 (BW)' },
        { id: 55, nome: '55 (BR)' },
        { id: 673, nome: '673 (BN)' },
        { id: 359, nome: '359 (BG)' },
        { id: 226, nome: '226 (BF)' },
        { id: 257, nome: '257 (BI)' },
        { id: 238, nome: '238 (CV)' },
        { id: 855, nome: '855 (KH)' },
        { id: 237, nome: '237 (CM)' },
        { id: 1, nome: '1 (CA)' },
        { id: 235, nome: '235 (TD)' },
        { id: 56, nome: '56 (CL)' },
        { id: 86, nome: '86 (CN)' },
        { id: 357, nome: '357 (CY)' },
        { id: 39, nome: '39 (VA)' },
        { id: 57, nome: '57 (CO)' },
        { id: 269, nome: '269 (KM)' },
        { id: 242, nome: '242 (CG)' },
        { id: 243, nome: '243 (CD)' },
        { id: 850, nome: '850 (KP)' },
        { id: 82, nome: '82 (KR)' },
        { id: 225, nome: '225 (CI)' },
        { id: 506, nome: '506 (CR)' },
        { id: 385, nome: '385 (HR)' },
        { id: 53, nome: '53 (CU)' },
        { id: 5999, nome: '5999 (CW)' },
        { id: 45, nome: '45 (DK)' },
        { id: 1767, nome: '1767 (DM)' },
        { id: 593, nome: '593 (EC)' },
        { id: 20, nome: '20 (EG)' },
        { id: 503, nome: '503 (SV)' },
        { id: 971, nome: '971 (AE)' },
        { id: 291, nome: '291 (ER)' },
        { id: 421, nome: '421 (SK)' },
        { id: 386, nome: '386 (SI)' },
        { id: 34, nome: '34 (ES)' },
        { id: 1, nome: '1 (US)' },
        { id: 372, nome: '372 (EE)' },
        { id: 251, nome: '251 (ET)' },
        { id: 63, nome: '63 (PH)' },
        { id: 358, nome: '358 (FI)' },
        { id: 679, nome: '679 (FJ)' },
        { id: 33, nome: '33 (FR)' },
        { id: 241, nome: '241 (GA)' },
        { id: 220, nome: '220 (GM)' },
        { id: 995, nome: '995 (GE)' },
        { id: 233, nome: '233 (GH)' },
        { id: 350, nome: '350 (GI)' },
        { id: 1473, nome: '1473 (GD)' },
        { id: 30, nome: '30 (GR)' },
        { id: 299, nome: '299 (GL)' },
        { id: 590, nome: '590 (GP)' },
        { id: 1671, nome: '1671 (GU)' },
        { id: 502, nome: '502 (GT)' },
        { id: 594, nome: '594 (GF)' },
        { id: 44, nome: '44 (GG)' },
        { id: 224, nome: '224 (GN)' },
        { id: 240, nome: '240 (GQ)' },
        { id: 245, nome: '245 (GW)' },
        { id: 592, nome: '592 (GY)' },
        { id: 509, nome: '509 (HT)' },
        { id: 504, nome: '504 (HN)' },
        { id: 852, nome: '852 (HK)' },
        { id: 36, nome: '36 (HU)' },
        { id: 91, nome: '91 (IN)' },
        { id: 62, nome: '62 (ID)' },
        { id: 98, nome: '98 (IR)' },
        { id: 964, nome: '964 (IQ)' },
        { id: 353, nome: '353 (IE)' },
        { id: null, nome: ' (BV)' },
        { id: 44, nome: '44 (IM)' },
        { id: 61, nome: '61 (CX)' },
        { id: 672, nome: '672 (NF)' },
        { id: 354, nome: '354 (IS)' },
        { id: 1441, nome: '1441 (BM)' },
        { id: 1345, nome: '1345 (KY)' },
        { id: 61, nome: '61 (CC)' },
        { id: 682, nome: '682 (CK)' },
        { id: 358, nome: '358 (AX)' },
        { id: 298, nome: '298 (FO)' },
        { id: 500, nome: '500 (GS)' },
        { id: null, nome: ' (HM)' },
        { id: 960, nome: '960 (MV)' },
        { id: 500, nome: '500 (FK)' },
        { id: 1670, nome: '1670 (MP)' },
        { id: 692, nome: '692 (MH)' },
        { id: 870, nome: '870 (PN)' },
        { id: 677, nome: '677 (SB)' },
        { id: 1649, nome: '1649 (TC)' },
        { id: 246, nome: '246 (UM)' },
        { id: 1284, nome: '1284 (VG)' },
        { id: 1340, nome: '1340 (VI)' },
        { id: 972, nome: '972 (IL)' },
        { id: 39, nome: '39 (IT)' },
        { id: 1876, nome: '1876 (JM)' },
        { id: 81, nome: '81 (JP)' },
        { id: 44, nome: '44 (JE)' },
        { id: 962, nome: '962 (JO)' },
        { id: 7, nome: '7 (KZ)' },
        { id: 254, nome: '254 (KE)' },
        { id: 996, nome: '996 (KG)' },
        { id: 686, nome: '686 (KI)' },
        { id: 965, nome: '965 (KW)' },
        { id: 961, nome: '961 (LB)' },
        { id: 856, nome: '856 (LA)' },
        { id: 266, nome: '266 (LS)' },
        { id: 371, nome: '371 (LV)' },
        { id: 231, nome: '231 (LR)' },
        { id: 218, nome: '218 (LY)' },
        { id: 423, nome: '423 (LI)' },
        { id: 370, nome: '370 (LT)' },
        { id: 352, nome: '352 (LU)' },
        { id: 52, nome: '52 (MX)' },
        { id: 377, nome: '377 (MC)' },
        { id: 853, nome: '853 (MO)' },
        { id: 389, nome: '389 (MK)' },
        { id: 261, nome: '261 (MG)' },
        { id: 60, nome: '60 (MY)' },
        { id: 265, nome: '265 (MW)' },
        { id: 223, nome: '223 (ML)' },
        { id: 356, nome: '356 (MT)' },
        { id: 212, nome: '212 (MA)' },
        { id: 596, nome: '596 (MQ)' },
        { id: 230, nome: '230 (MU)' },
        { id: 222, nome: '222 (MR)' },
        { id: 262, nome: '262 (YT)' },
        { id: 691, nome: '691 (FM)' },
        { id: 373, nome: '373 (MD)' },
        { id: 976, nome: '976 (MN)' },
        { id: 382, nome: '382 (ME)' },
        { id: 1664, nome: '1664 (MS)' },
        { id: 258, nome: '258 (MZ)' },
        { id: 264, nome: '264 (NA)' },
        { id: 674, nome: '674 (NR)' },
        { id: 977, nome: '977 (NP)' },
        { id: 505, nome: '505 (NI)' },
        { id: 227, nome: '227 (NE)' },
        { id: 234, nome: '234 (NG)' },
        { id: 683, nome: '683 (NU)' },
        { id: 47, nome: '47 (NO)' },
        { id: 687, nome: '687 (NC)' },
        { id: 64, nome: '64 (NZ)' },
        { id: 968, nome: '968 (OM)' },
        { id: 31, nome: '31 (NL)' },
        { id: 92, nome: '92 (PK)' },
        { id: 680, nome: '680 (PW)' },
        { id: 970, nome: '970 (PS)' },
        { id: 507, nome: '507 (PA)' },
        { id: 675, nome: '675 (PG)' },
        { id: 595, nome: '595 (PY)' },
        { id: 51, nome: '51 (PE)' },
        { id: 689, nome: '689 (PF)' },
        { id: 48, nome: '48 (PL)' },
        { id: 1, nome: '1 (PR)' },
        { id: 974, nome: '974 (QA)' },
        { id: 44, nome: '44 (GB)' },
        { id: 236, nome: '236 (CF)' },
        { id: 420, nome: '420 (CZ)' },
        { id: 1809, nome: '1809 (DO)' },
        { id: 211, nome: '211 (SS)' },
        { id: 262, nome: '262 (RE)' },
        { id: 250, nome: '250 (RW)' },
        { id: 40, nome: '40 (RO)' },
        { id: 7, nome: '7 (RU)' },
        { id: 212, nome: '212 (EH)' },
        { id: 685, nome: '685 (WS)' },
        { id: 1684, nome: '1684 (AS)' },
        { id: 590, nome: '590 (BL)' },
        { id: 1869, nome: '1869 (KN)' },
        { id: 378, nome: '378 (SM)' },
        { id: 1599, nome: '1599 (MF)' },
        { id: 508, nome: '508 (PM)' },
        { id: 1784, nome: '1784 (VC)' },
        { id: 290, nome: '290 (SH)' },
        { id: 1758, nome: '1758 (LC)' },
        { id: 239, nome: '239 (ST)' },
        { id: 221, nome: '221 (SN)' },
        { id: 381, nome: '381 (RS)' },
        { id: 248, nome: '248 (SC)' },
        { id: 232, nome: '232 (SL)' },
        { id: 65, nome: '65 (SG)' },
        { id: 1721, nome: '1721 (SX)' },
        { id: 963, nome: '963 (SY)' },
        { id: 252, nome: '252 (SO)' },
        { id: 94, nome: '94 (LK)' },
        { id: 27, nome: '27 (ZA)' },
        { id: 249, nome: '249 (SD)' },
        { id: 46, nome: '46 (SE)' },
        { id: 41, nome: '41 (CH)' },
        { id: 597, nome: '597 (SR)' },
        { id: 47, nome: '47 (SJ)' },
        { id: 268, nome: '268 (SZ)' },
        { id: 992, nome: '992 (TJ)' },
        { id: 66, nome: '66 (TH)' },
        { id: 886, nome: '886 (TW)' },
        { id: 255, nome: '255 (TZ)' },
        { id: 246, nome: '246 (IO)' },
        { id: null, nome: ' (TF)' },
        { id: 670, nome: '670 (TL)' },
        { id: 228, nome: '228 (TG)' },
        { id: 690, nome: '690 (TK)' },
        { id: 676, nome: '676 (TO)' },
        { id: 1868, nome: '1868 (TT)' },
        { id: 216, nome: '216 (TN)' },
        { id: 993, nome: '993 (TM)' },
        { id: 90, nome: '90 (TR)' },
        { id: 688, nome: '688 (TV)' },
        { id: 380, nome: '380 (UA)' },
        { id: 256, nome: '256 (UG)' },
        { id: 598, nome: '598 (UY)' },
        { id: 998, nome: '998 (UZ)' },
        { id: 678, nome: '678 (VU)' },
        { id: 58, nome: '58 (VE)' },
        { id: 84, nome: '84 (VN)' },
        { id: 681, nome: '681 (WF)' },
        { id: 967, nome: '967 (YE)' },
        { id: 253, nome: '253 (DJ)' },
        { id: 260, nome: '260 (ZM)' },
        { id: 263, nome: '263 (ZW)' }
      ],
      genero: [
        {
          id: 0,
          nome: 'Masculino'
        },
        {
          id: 1,
          nome: 'Feminino'
        }
      ],
      tipoDoc: [
        {
          id: 0,
          nome: 'BI'
        },
        {
          id: 1,
          nome: 'Passaporte'
        }
      ]
    }
  },
  getters: {
    nacionalidades: (state) => state.picklists.nacionalidades,
    listaIdiomas: (state) => state.picklists.idiomas,
    areasEstudo: (state) => state.picklists.areasEstudo,
    areasFormacao: (state) => state.picklists.areasFormacao,
    habilitacoes: (state) => state.picklists.habilitacoes
      .map(habilitacao => ({ nome: habilitacao.designcacao, id: habilitacao.id })),
    paises: (state) => state.picklists.paises,
    duracoes: (state) => state.picklists.duracoes
      .map(duracao => ({ nome: duracao.designacao, id: duracao.id })),
    experiencias: (state) => state.picklists.experiencias,
    grausAcademicos: (state) => state.picklists.grausAcademicos,
    disponibilidades: (state) => state.picklists.disponibilidades,
    avatares: (state) => state.picklists.avatares,
    perguntas: (state) => state.picklists.perguntas,
    valencias: (state) => state.picklists.valencias
      .map(valencia => ({ nome: valencia.descricao, id: valencia.id })),
    funcoes: (state) => state.picklists.funcoes,
    sectores: (state) => state.picklists.sectores,
    indicativos: (state) => state.picklists.indicativos,
    genero: (state) => state.picklists.genero,
    listaTipoDoc: (state) => state.picklists.tipoDoc,
    listaNivelIdioma: (state) => state.picklists.nivelIdiomas,
    areaEstudo: (state) => (id) => state.picklists.areasEstudo.filter(estudo => estudo.id === id)[0],
    grauAcademico: (state) => (id) => state.picklists.grausAcademicos.filter(grau => grau.id === id)[0],
    getDuracao: (state) => (id) => state.picklists.duracoes.filter(duration => duration.id === id)[0],
    getFormacao: (state) => (id) => state.picklists.areasFormacao.filter(formacao => formacao.id === id)[0],
    getIdioma: (state) => (id) => state.picklists.idiomas.filter(idioma => idioma.id === id)[0],
    getFuncao: (state) => (id) => state.picklists.funcoes.filter(funcao => funcao.id === id)[0],
    getValencia: (state) => (id) => state.picklists.valencias.filter(valencia => valencia.id === id)[0],
    getPergunta: (state) => (id) => state.picklists.perguntas.filter(pergunta => pergunta.id === id)[0],
    getExperiencia: (state) => (id) => state.picklists.experiencias.filter(experiencia => experiencia.id === id)[0],
    getDisponibilidades: (state) => (id) => state.picklists.disponibilidades.filter(disponibilidade => disponibilidade.id === id)[0],
    getHabilityId: (state) => id => {
      if (!state.picklists.habilitacoes.length) return ''
      const obj = state.picklists.habilitacoes.find(item => item.id === id) || ''
      if (obj === null) return ''
      return {
        id: obj.id,
        nome: obj.designcacao
      }
    },
    getIdiomaId: (state) => id => {
      if (!state.picklists.idiomas.length) return ''
      return state.picklists.idiomas.find(item => item.id === id) || ''
    },
    getPerguntaId: (state) => id => {
      const perg = state.picklists.perguntas.find(p => p.respostas.find(r => r.id === id))
      const res = perg.respostas.find(res => res.id === id)
      return res
    }
  },
  mutations: {
    setState (state, payload) {
      state.register = {
        ...state.register,
        ...payload
      }
    },
    resetState (state) {
      state.register = { ...initialState,
        educacoes: [],
        formacoes: [],
        funcoes: [],
        pontosMelhorias: [],
        pontosFortes: [],
        idiomas: [],
        experienciasProfissional: [],
        respostas: [],
        disponibilidades: []
      }
    },
    setPicklist (state, picklist) {
      state.picklists = {
        ...state.picklists,
        ...picklist
      }
    }
  },
  actions: {
    getPicklists (context) {
      return RegisterService.getRegisterList()
        .then(({ data }) => {
          var disponibilidades = data.object.paises[data.object.paises.findIndex(p => p.nome === 'Angola')].cidades || []
          context.commit('setPicklist', { ...data.object, disponibilidades })
        })
    }
  }
}
