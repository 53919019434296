<template>
<div>
  <figure>
    <router-link :to="{name: 'Home'}">
      <img :src="require('@/assets/welcome-rise.svg')"  width="760">
      <h1>Bem-vindo ao RISE</h1>
    </router-link>
  </figure>
</div>
</template>
<script>
export default {
  name: 'welcome-rise',
  data () {
    return {
      timerId: -1
    }
  },
  mounted () {
    const _this = this
    if (!this.$route.params.form) {
      return this.$router.push({ name: 'Form' })
    }
    this.timerId = setTimeout(function () {
      _this.$router.push({ name: 'Home' })
    }, 5000)
  },
  destroyed () {
    clearTimeout(this.timerId)
  }
}
</script>
<style lang="scss" scoped>
div {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  figure {
    position: relative;
    h1 {
      position: absolute;
      left: 50%;
      top: 55%;
      font-family: 'NexaBold';
      transform: translate(-50%,-55%);
      font-size: 2rem;
      color: #6A4C7C;
    }
  }
}
</style>
