<template>
  <main role="main" class="main" :class="{active: isShowMenu}" :style="{'background-color': themeColorMenu}">
    <header role="header">
      <router-link class="logo" :to="{name: 'Home'}">
        <icon-logo class="logo-m" :viewbox="logo.viewbox"  :viewbox-width="logo.viewboxWidth" :width="logo.width" :height="logo.height" v-show="isShowMenu" :color="themeMenu.colorIcon" @hideMenu="isShowMenu = false"/>
        <icon-logo class="logo-m" :viewbox="logo.viewbox" :viewbox-width="logo.viewboxWidth" :width="logo.width" :height="logo.height" v-show="!isShowMenu" :color="theme.colorIcon" @hideMenu="isShowMenu = false"/>
      </router-link>
      <ul :class="{'display': ul.display}" id="menu_nav_id" class="menu-nav" v-show="!isShowMenu" v-if="!isForm">
        <li><router-link to="/about">O QUE É A RISE WORK?</router-link></li>
        <li><router-link to="/candidate">PROFISSIONAIS DISPONÍVEIS</router-link></li>
        <li v-if="AuthenticateParceiro || AuthenticateProfissional"><router-link :to="{name: MinhaAreaRouter}">MINHA ÁREA</router-link></li>
      </ul>
      <icon-menu v-show="!routerActive" class="btn-menu" @toggleMenu="handlerMenu" :isShow="isShowMenu" :color="theme.colorMenu"/>
    </header>
    <section role="section" class="container--main">
      <section class="content-mobile">
        <ul class="menu-mobile">
          <li @click="isShowMenu = false"><router-link to="/about">O QUE É A RISE WORK?</router-link></li>
          <li @click="isShowMenu = false"><router-link to="/candidate">PROFISSIONAIS</router-link></li>
          <li v-if="AuthenticateParceiro || AuthenticateProfissional"><router-link :to="{name: MinhaAreaRouter}">MINHA ÁREA</router-link></li>
        </ul>
      </section>
      <section class="content">
        <ul v-for="(menu, index) of menus" :key="index">
          <li v-for="(item, i) of menu.items" :key="i">
            <router-link to="#" style="color: #fff; text-decoration: none; font-family:NexaLight">{{item}}</router-link>
          </li>
        </ul>
      </section>
      <footer role="footer">
        <ul>
          <li><router-link to="/"><img :src="require('@/assets/img/Grupo 735.svg')" alt="link facebook"></router-link></li>
          <li><router-link to="/"><img :src="require('@/assets/img/Grupo 734.svg')" alt="link twettir"></router-link></li>
          <li><router-link to="/"><img :src="require('@/assets/img/Grupo 736.svg')" alt="link linkedin"></router-link></li>
        </ul>
      </footer>
    </section>
  </main>
</template>
<script>
import { isAuthenticated, isAuthenticatedParceiro } from '@/utils/auth'

export default {
  name: 'menuComponent',
  updated () {
    if (isAuthenticatedParceiro()) {
      this.AuthenticateParceiro = true
      this.MinhaAreaRouter = 'MyAreaParceiro'
    } else if (isAuthenticated()) {
      this.AuthenticateProfissional = true
      this.MinhaAreaRouter = 'MyAreaProfissional'
    }
  },
  props: {
    theme: {
      type: Object,
      default: function () {
        return {
          colorIcon: 'primary'
        }
      }
    }
  },
  components: {
    'icon-menu': require('@/components shared/icon-menu').default,
    'icon-logo': require('@/components shared/icon-logo-work').default
  },
  data () {
    return {
      AuthenticateParceiro: false,
      AuthenticateProfissional: false,
      MinhaAreaRouter: '',
      themeMenu: this.theme,
      isShowMenu: false,
      isForm: false,
      ul: {
        display: false
      },
      themeColorMenu: this.$route.meta.theme.themeMenu,
      verifyAuth: false,
      logo: {
        width: 190,
        viewboxWidth: 500,
        height: 130.518,
        // width: 204.01,
        // height: 66.518,
        viewbox: 500
      },
      menus: [{
        items: ['Sobre nós', 'Como funciona', 'Nossa equipa', 'Notícias']
      },
      {
        items: ['E-mail', 'Newsletter', 'Localização']
      }]
    }
  },
  computed: {
    routerActive () {
      let routes = ['form-empresa', 'Form']
      return routes.includes(this.$route.name)
    }
  },
  mounted () {
    const ScreenSize = window.screen.width
    if (this.$route.name === 'Form' || this.$route.name === 'form-empresa') {
      this.isForm = true
    }
    if (ScreenSize < 768) {
      this.logo.width = 90
      this.logo.height = 100
      this.logo.viewboxWidth = 500
      this.logo.viewbox = 500
      this.ul.display = true
    }
  },
  methods: {
    handlerMenu () {
      this.isShowMenu = !this.isShowMenu
      if (this.isShowMenu) {
        this.themeMenu.colorIcon = 'secondary'
      } else {
        this.themeMenu.colorIcon = 'primary'
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.themeColorMenu = (to.meta && to.meta.theme && to.meta.theme.themeMenu) || '#fff'
      if (this.$route.name === 'Form' || this.$route.name === 'form-empresa') {
        this.isForm = true
      } else if (this.isForm !== false) {
        this.isForm = false
      } else {
        this.isForm = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@media(min-width:768px){
  .work_logo{
    padding-top:140px!important;
    width: 160px!important;
  }
}
#menu_nav_id {
  display: none;
}
.content-mobile{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  .menu-mobile{
    list-style: none;
    padding: 0!important;
    width: 100%;
    li{
        margin: 0!important;
        padding:22px  0 0 0;
      a{
        color: #fff;
        font-size: 25px;
        text-decoration: none;
        font-weight: 100;
      }
    }
    li::after{
    content: '';
    display: block;
    margin: 25px 0 0 0;
    width: 100%;
    height: 1px;
    background: #fff;
  }
  }
}
@media screen and (min-width: 768px){
  #menu_nav_id {
    display: flex;
  }
}
@media(max-width: 768px){
  .logo-m{
    margin-left: -25px;
    margin-top: -25px;
  }
  .content{
    display: none!important;
  }
  footer{
    display: none!important;
  }
  .display{
    // display: none!important;
  }
  .btn-menu{
    z-index: 9990!important;
    margin-top: -50px;
    margin-right: -30px;
  }
}
.main {
  width: 100%;
  padding-top: 0;
  padding-right: 50px;
  padding-left: 50px;
  background-color: #FDB00B;
  height: 100px;
  z-index: 1000;
  .container--main {
    max-height: 0;
    overflow: hidden;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding-top: 40px;
  }
}
.main.active {
  height: 100vh;
  top: 0;
  position: fixed;
  .container--main {
    max-height: 70vh;
  }
}
.btn-menu{
  @media(min-width: 768px){
    display: none!important;
  }
}
.btn-menu,
.logo{
  z-index: 10!important;
  // @media(min-width:768px){
  //   margin-left: -150px;
  // }
}
.menu-nav {
  list-style: none;
  padding: 0;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 3rem;
  z-index: 9;
  li {
    margin: 0 1rem;
    a {
      text-decoration: none;
      color: #fff;
      cursor: pointer;
      font-size: .97rem;
    }
    .active-route.router-link-active {
      color: #342041;
      border: 1px solid #342041;
      padding: .3rem .8rem;
      border-radius: 30px;
    }
  }
}
.main > section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 3rem;
  color: #fff;
  .content {
    display: flex;
    align-items: flex-end;
    text-align: left;
    margin-bottom: 3rem;
    margin-right: 5rem;
    font-size: 2.2rem;
    font-family: "NexLight";
    ul {
      list-style: none;
      padding: 0;
      margin-right: 6rem;
    }
  }
  footer ul{
    display: flex;
    list-style: none;
    justify-content: space-around;
    margin-right: 11.5rem;
    li {
      margin: 0 6px;
    }
    li:first-child {
      margin-left: 0;
    }
    li:last-child {
      margin-right: 0;
    }
  }
}
</style>
