// import axios from 'axios'
import { url, setTokenProfissional, setTokenParceiro } from '@/api/base-url'
import router from '@/router/'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    LOGIN: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        url.post('auth/login', payload)
          .then(({ data, status }) => {
            if (status === 200) {
              setTokenProfissional(data.object.acessToken)
              router.push({ name: 'MyAreaProfissional' })
              resolve(true)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    LOGINPARCEIRO: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        url.post('auth/login', payload)
          .then(({ data, status }) => {
            if (status === 200) {
              setTokenParceiro(data.object.acessToken)
              if (localStorage.getItem('@Rise_Parceiro_Continue_Activite') === 'yes') {
                router.push({ name: 'Home' })
              } else if (localStorage.getItem('@Rise_Parceiro_Continue_Activite2') === 'yes') {
                router.push({ name: 'Candidate' })
              } else {
                router.push({ name: 'MyAreaParceiro' })
                resolve(true)
              }
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
