<template>
  <button role="main" @click="handlerToggleMenu">
    <svg width="45" height="28" viewBox="0 0 40 30" xmlns="http://www.w3.org/2000/svg">
      <g class="group--line">
      <line x1="0%" y1="5%" x2="100%" y2="5%" :stroke="color"/>
      <line :x1="active ? '0%' : '45%'" y1="30%" :x2="active ? '60%' : '100%'" y2="30%" :stroke="color"/>
      <line :x1="active ? '45%' : '0%'" y1="50%" :x2="active ? '100%' : '60%'" y2="50%" :stroke="color"/>
      <line :x1="active ? '0%' : '15%'" y1="75%" :x2="active ? '75%' : '100%'" y2="75%" :stroke="color"/>
      </g>
    </svg>
  </button>
</template>
<script>
export default {
  name: 'icon-menu',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  data () {
    return {
      active: this.isShow
    }
  },
  methods: {
    handlerToggleMenu () {
      this.$emit('toggleMenu')
      this.active = !this.active
    }
  }
}
</script>
<style lang="scss" scoped>

button {
  background-color: transparent;
  margin: 0;
  padding: 0;
  border-style: none;
  outline-style: none;
  height: 35px;
  line-height: 55px;
  cursor: pointer;
  svg {
   .group--line {
    stroke: #fff;
    stroke-width: 2;
   }
  }
}
</style>
