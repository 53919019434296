<template>
  <div class="container-info" :class="[color]">
    <p v-if="title" class="title">{{title}}</p>
    <p v-if="description" class="description" v-html="description"></p>
  </div>
</template>
<script>
export default {
  name: 'header-title',
  props: {
    title: String,
    description: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
<style lang="scss" scoped>
.primary {
    color: #6a4c7cc0 !important;
  }
.secondary {
  color: #000;
}
.container-info {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: left;
    .title {
      font-size: 26px;
      text-align: left;
      word-break: break-all;
      font-family: "Roboto", sans-serif !important;
    }
    .description {
      padding-top: 20px;
      margin-left: 1.5em;
      text-align: left;
      font-size: 16px;
      max-width: 70%;
    }

}
@media screen and (min-width: 768px) {
  .container-info {
    .title {
      font-size: 40px;
    }
    .description {
      font-size: 21px;
    }
  }
}
</style>
