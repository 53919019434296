<template>
  <svg @click="$emit('hideMenu')"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${viewboxWidth} ${viewbox}`"
  >
  <g class="a" :fill="colors[color]">
    <g transform="translate(0 0)">
      <path
        class="cls-1"
        d="M7.74,94H85.55c21.64,0,38.42,6.08,49.6,17.26,9.48,9.48,14.59,22.86,14.59,38.9v.49c0,27.48-14.83,44.74-36.47,52.77l41.58,60.78H111.08L74.61,209.75H45.19v54.46H7.74Zm75.38,82.67c18.23,0,28.69-9.73,28.69-24.07v-.49c0-16.05-11.19-24.31-29.42-24.31H45.19v48.87Z"
      />
      <rect class="cls-1" x="167.01" y="94.01" width="37.45" height="170.2" />
      <path
        class="cls-1"
        d="M217.1,239.41l22.13-26.5c15.31,12.64,31.36,20.67,50.82,20.67,15.31,0,24.55-6.08,24.55-16.05V217c0-9.48-5.83-14.34-34.28-21.64-34.29-8.75-56.41-18.23-56.41-52v-.49c0-30.88,24.8-51.3,59.57-51.3,24.8,0,45.95,7.78,63.22,21.64l-19.45,28.2C312.17,131,297.34,124.64,283,124.64s-21.88,6.57-21.88,14.84V140c0,11.19,7.29,14.84,36.72,22.37,34.52,9,54,21.4,54,51.06v.49c0,33.8-25.77,52.76-62.48,52.76a108.28,108.28,0,0,1-72.22-27.23"
      />
      <polygon
        class="cls-1"
        points="366.16 94.01 494.54 94.01 494.54 127.32 403.36 127.32 403.36 161.85 483.6 161.85 483.6 195.16 403.36 195.16 403.36 230.9 495.76 230.9 495.76 264.21 366.16 264.21 366.16 94.01"
      />
      <polygon
        class="cls-2"
        points="140.91 288.08 120.24 349.43 98.94 287.81 77.95 287.81 56.65 349.43 35.97 288.08 7.74 288.08 45.59 392.49 66.77 392.49 88.16 332.75 109.55 392.49 130.88 392.49 168.57 288.08 140.91 288.08"
      />
      <path
        class="cls-2"
        d="M222.53,286.45c-31.7,0-56.53,23.5-56.53,53.49v.28c0,30.34,24.18,53.22,56.24,53.22,31.7,0,56.53-23.5,56.53-53.5v-.27c0-30.34-24.18-53.22-56.24-53.22m0,82.81c-16.81,0-29.49-12.6-29.49-29.32v-.27c0-16.63,12.55-29.18,29.2-29.18,16.82,0,29.49,12.66,29.49,29.45v.28c0,16.56-12.55,29-29.2,29"
      />
      <path
        class="cls-2"
        d="M381.29,322.7v-.27a32.33,32.33,0,0,0-9.63-23.68c-7.48-7-18.44-10.67-31.69-10.67H291.26V391.81h26.19V358.68H332.8l24.45,33.13h31.63l-28.5-38.17C373.75,348,381.29,337,381.29,322.7M317.45,312h21.1c7.42,0,16.27,2,16.27,11.4v.27c0,8.51-8.33,11.54-16.13,11.54H317.45Z"
      />
      <polygon
        class="cls-2"
        points="449.19 333.64 495.47 288.08 461.43 288.08 420.25 329.65 420.25 288.08 394.06 288.08 394.06 391.81 420.25 391.81 420.25 361.73 431 351.18 463.28 391.81 495.76 391.81 449.19 333.64"
      />
    </g>
  </g>
  </svg>
</template>
<script>
export default {
  name: 'icon-logo',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    height: {
      type: Number,
      default: 66.518
    },
    viewboxWidth: {
      type: Number,
      default: 204.01
    },
    width: {
      type: Number,
      default: 204.01
    },
    viewbox: {
      type: [Number, String],
      default: 97
    }
  },
  data () {
    return {
      colors: {
        primary: '#ffffff',
        secondary: '#26a0a5',
        tertiary: '#FDB00B',
        lilas: '#503660',
        bluelight: '#78C4C7'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.a,
.b,
.c {
  isolation: isolate;
}
.b,
.c {
  mix-blend-mode: screen;
}
</style>
