<template>
  <main>
    <h1>404</h1>
    <p>Oops! Página não encontrada.</p>
    <a class="button" @click="goToHome" href="#"
      ><i class="icon-home"></i> Voltar na página inicial</a
    >
  </main>
</template>

<script>
import router from '@/router/'
export default {
  name: 'Page-not-found',
  methods: {
    goToHome () {
      router.push({ name: 'Home' })
    }
  }
}
</script>
<style  scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:400, 100, 300, 500);

main {
  background-color: #4b335a;
  /* background-color: #4B368A;  */
  color: #FDB00B;
  font-size: 100%;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.button {
  font-weight: 300;
  color: #FDB00B;
  font-size: 1.2em;
  text-decoration: none;
  border: 1px solid #FDB00B;
  padding: 0.5em;
  border-radius: 3px;
  float: left;
  margin: 6em 0 0 0;
  /* left: 50%; */
  position: relative;
  transition: all 0.3s linear;
}

.button:hover {
  background-color: #4b335a;
  color: #fff;
}

p {
  font-size: 2em;
  text-align: center;
  font-weight: 100;
}

@media(max-width: 786px){
  h1 {
    font-size: 10em!important;
  }
}
h1 {
  text-align: center;
  font-size: 15em;
  font-weight: 100;
  text-shadow: #4b235a 1px 1px, #4b235a 2px 2px, #4b235a 3px 3px,
    #4b255a 4px 4px, #4b255a 5px 5px, #4b255a 6px 6px, #4b255a 7px 7px,
    #4b245b 8px 8px, #4b245a 9px 9px, #4b245a 10px 10px, #4b245a 11px 11px,
    #4b265a 12px 12px, #4b265a 13px 13px, #4b265a 14px 14px, #4b265a 15px 15px,
    #4b275a 16px 16px, #4b266a 17px 17px, #4b266a 18px 18px, #4b266a 19px 19px,
    #4b266b 20px 20px, #4b266b 21px 21px, #4b266b 22px 22px, #4b266b 23px 23px,
    #4b266c 24px 24px, #4b266d 25px 25px, #4b266d 26px 26px, #4b266d 27px 27px,
    #4b266e 28px 28px, #4b266e 29px 29px, #4b266e 30px 30px, #4b266e 31px 31px,
    #4b266f 32px 32px, #4b266f 33px 33px, #4b276f 34px 34px, #4b276f 35px 35px,
    #4b276a 36px 36px, #4b276a 37px 37px, #4b276a 38px 38px, #4b276a 39px 39px,
    #4b276b 40px 40px, #4b276b 41px 41px, #4b276c 42px 42px, #4b276c 43px 43px,
    #4b276b 44px 44px, #4b276b 45px 45px, #4b276b 46px 46px, #4b276b 47px 47px,
    #4b276c 48px 48px, #4b276c 49px 49px, #4b276d 50px 50px, #4b276e 51px 51px,
    #4b276e 52px 52px, #4b276e 53px 53px, #4b276e 54px 54px, #4b276f 55px 55px;
}
</style>
