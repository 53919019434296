import { url } from '@/api/base-url'

const Active = {
  namespaced: true,
  actions: {
    activation: (_, Token) =>
      url.post('auth/confirmarEmail/', {
        email: '',
        codigo: Token
      })
  }
}

export { Active }
