import { url } from '@/api/base-url'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    SendPedido: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        url.post('parceiros/profissionais/AddPedido', payload)
          .then(({ data, status }) => {
            if (status === 200) {
              resolve(true)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    TogleFavorite: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        url.post('parceiros/profissionais/ToggleFavorito', payload)
          .then(({ status }) => {
            if (status === 200) {
              resolve(true)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    AddVisualizacao: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        url.post('parceiros/profissionais/AddVisualizacao', payload)
          .then(({ data, status }) => {
            if (status === 200) {
              resolve(true)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
