import Vue from 'vue'
import NProgress from 'nprogress'
import VueRouter from 'vue-router'

import 'nprogress/nprogress.css'

import WelcomeRise from '@/views/welcome-rise'
import FotFound from '@/views/NotFound'
import DefaultLayout from '@/layout/Default.vue'
import { protectedRoutes } from './protected.routes'

Vue.use(VueRouter)

const routes = [
  ...protectedRoutes,
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'Home',
        meta: { theme: {
          themeMenu: 'rgb(255, 198,0)',
          colorIcon: 'primary',
          colorMenu: '#fff'
        }
        },
        component: () => import('@/views/Home.vue')
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Auth/Login.vue'),
        meta: {
          theme: { themeMenu: '#4B335A' }
        }
      },
      {
        path: '/RecoveryPassword',
        name: 'RecoveryPassword',
        component: () => import('@/views/Auth/RecoveryPassword.vue'),
        meta: {
          theme: { themeMenu: '#4B335A' }
        }
      },
      {
        path: '/RecoveryPasswordPartner',
        name: 'RecoveryPasswordPartner',
        component: () => import('@/views/Auth/RecoveryPasswordPartner.vue'),
        meta: {
          theme: { themeMenu: '#26A0A5' }
        }
      },
      {
        path: '/recuperacao',
        name: 'NewPassword',
        component: () => import('@/views/Auth/NewPassword.vue')
      },
      {
        path: '/login-parceiro',
        name: 'LoginParceiro',
        component: () => import('@/views/Auth/LoginParceiro.vue'),
        meta: {
          theme: { themeMenu: '#26A0A5' }
        }
      },
      {
        path: '/search',
        name: 'Search',
        component: () => import('@/views/Search.vue')
      },
      {
        path: '/greeting',
        name: 'Greeting',
        component: () => import('@/views/Greeting.vue'),
        meta: {
          theme: { themeMenu: '#FFF', colorIcon: 'bluelight' }
        }
      },
      {
        path: 'activacao',
        name: 'Ativaction',
        component: () => import('@/views/Auth/Ativaction.vue')
      },
      {
        path: '/logout',
        name: 'Logout',
        component: () => import('@/views/Auth/Logout.vue')
      },
      {
        path: '/form',
        name: 'Form',
        component: () => import('@/views/FormCandidato.vue'),
        meta: {
          theme: {
            themeMenu: '#fff',
            colorIcon: 'lilas',
            colorMenu: '#444'
          }
        }
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About.vue'),
        meta: {
          theme: { themeMenu: '#FFCC00' }
        }
      },
      {
        path: '/candidate',
        name: 'Candidate',
        component: () => import('@/views/Profissional.vue'),
        meta: {
          theme: { themeMenu: '#FDB00B' }
        }
      },
      {
        path: '/formempresa',
        name: 'form-empresa',
        component: () => import('@/views/FormEmpresa'),
        meta: {
          theme: {
            themeMenu: '#fff',
            colorIcon: 'bluelight',
            colorMenu: '#444',
            colorButton: '#78C4C7'
          }
        }
      }
    ]
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: WelcomeRise
  },
  {
    path: '*',
    name: 'not_found',
    component: FotFound
    // redirect: { name: 'Home' }
  }
]

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active-route',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, next) => {
    return { x: 0, y: 0 }
  }
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    NProgress.configure({ easing: 'ease', speed: 2000, showSpinner: true })
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
