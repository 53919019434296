import axios from 'axios'
/* 'http://work-api.rise.ao/', */
/* 'http://riseworkapi-dev.digitalfactory.co.ao/', */
const http = axios.create({
  baseURL: 'https://work-api.rise.ao/',
  headers: {
    ContentType: 'application/json',
    'mode': 'cors'
  }
})

http.interceptors.request.use((req) => {
  const tokenProfissional = sessionStorage.getItem('@rise_token_profissional')
  const tokenParceiro = sessionStorage.getItem('@rise_token_parceiro')
  if (tokenProfissional) {
    req.headers['Authorization'] = `Bearer ${tokenProfissional}`
  } else if (tokenParceiro) {
    req.headers['Authorization'] = `Bearer ${tokenParceiro}`
  }
  return req
})

const _decodeToken = token => {
  const base64HeaderUrl = token.split('.')[1]
  const base64Header = base64HeaderUrl.replace('-', '+').replace('_', '/')
  const headerData = JSON.parse(window.atob(base64Header))

  return headerData
}

const setTokenProfissional = token => {
  const decoded = _decodeToken(token)

  sessionStorage.setItem('@rise_token_profissional', token)
  sessionStorage.setItem('@rise_token_profissional_created', decoded.iat)
  sessionStorage.setItem('@rise_token_profissional_expiration', decoded.exp)

  return true
}

const setTokenParceiro = token => {
  const decoded = _decodeToken(token)

  sessionStorage.setItem('@rise_token_parceiro', token)
  sessionStorage.setItem('@rise_token_parceiro_created', decoded.iat)
  sessionStorage.setItem('@rise_token_parceiro_expiration', decoded.exp)

  return true
}

export { http as url, setTokenProfissional, setTokenParceiro }
